@import './variables';

// Bootstrap
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/print';

@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/alert';

@import '~bootstrap/scss/utilities/align';
@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/utilities/borders';
@import '~bootstrap/scss/utilities/clearfix';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/embed';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/float';
@import '~bootstrap/scss/utilities/overflow';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/utilities/screenreaders';
@import '~bootstrap/scss/utilities/shadows';
@import '~bootstrap/scss/utilities/stretched-link';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/visibility';

/*
 * Theme
 */

body {
    -webkit-font-smoothing: antialiased;
}

hr {
    border: 0;
    border-bottom: 1px solid #cdd4d6;
}

.no-transition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

// Symfony Forms error badge: screen readers only
.form-error-icon {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Buttons

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-outline-variant($value);
        background: rgba($value, 0.1);
        border: 0;
    }

    .btn-clean-#{$color} {
        @include button-outline-variant($value);
        border: 0;
    }
}

// Alerts

.alert-error {
    @include alert-variant(theme-color-level('danger', $alert-bg-level), theme-color-level('danger', $alert-border-level), theme-color-level('danger', $alert-color-level));
}

// Card

.list-group-item {
    padding: 1.5rem 2rem;
    border: 1px solid #f1f4f8;
}
